/*
// === Reset ===
*/

html,
body {
  @apply font-sans text-14/20 font-400 text-gray-900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
// === Selection ===
*/

* {
  &::-moz-selection {
    background-color: theme('colors.citron-100');
    color: theme('colors.blue-900');
  }
  &::selection {
    background-color: theme('colors.citron-100');
    color: theme('colors.blue-900');
  }
}

.no-focus:focus {
  outline: none;
}

/*
// === Utilities ===
*/

.line-break {
  ol,
  ul,
  p {
    @apply mb-1;

    &:last-child {
      margin-bottom: 0;
    }

    ol,
    ul {
      margin-bottom: 0;
    }
  }
}

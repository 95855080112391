button,
.button {
  @apply font-sans text-14/20 font-400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: 400;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  appearance: none;
  color: inherit;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &.loading {
      cursor: wait !important;

      * {
        cursor: wait !important;
      }
    }
  }

  &.loading {
    cursor: wait !important;

    * {
      cursor: wait !important;
    }
  }

  /*
  // === Reset Overrides ===
  */

  &.mx-a {
    margin-left: auto;
    margin-right: auto;
  }

  &.block {
    display: block;
  }
  &.flex {
    display: flex;
  }

  &.text-left {
    text-align: left;
  }
}

@layer components {
  .button.button-size-small-medium {
    @apply px-2 font-500;
    height: 33px;
    font-size: 12px;
    line-height: 33px;
    border-radius: 33px;
  }

  .button.button-size-medium {
    @apply px-2 font-500;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    border-radius: 40px;
  }

  .button.button-size-medium-ai {
    @apply px-2 font-500;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    border-radius: 44px;
  }

  .button.button-size-medium-large-padding {
    @apply px-8 font-500;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    border-radius: 40px;
  }

  .button.button-size-large {
    @apply px-3 font-500;
    height: 56px;
    font-size: 22px;
    line-height: 56px;
    border-radius: 56px;
  }

  .button.button-size-xxl {
    @apply px-3 font-500;
    height: 68px;
    font-size: 23px;
    line-height: 68px;
    border-radius: 68px;
  }

  .button.button-size-circle-medium {
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 44px;
  }

  .button.button-primary-citron {
    @apply bg-citron-500 text-blue-1000 duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-citron-700;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-800');
      outline-offset: 2px;
    }
  }

  .button.button-primary-violet {
    @apply bg-violet-800 text-white duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-violet-900;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-800');
      outline-offset: 2px;
    }
  }

  .button.button-primary-violet-lighter {
    @apply bg-violet-700 text-white duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-violet-800;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-800');
      outline-offset: 2px;
    }
  }

  .button.button-primary-violet-xxl {
    @apply bg-violet-700 text-white duration-400 ease-appear;
    outline: 7px solid white;
    box-shadow: -7.334px 84.341px 23.836px 0px rgba(0, 0, 0, 0), -4.584px 54.088px 22.002px 0px rgba(0, 0, 0, 0.02),
      -2.75px 30.253px 18.335px 0px rgba(0, 0, 0, 0.05), -0.917px 13.751px 13.751px 0px rgba(0, 0, 0, 0.09),
      0px 3.667px 7.334px 0px rgba(0, 0, 0, 0.1);

    &:hover,
    &:focus,
    &.active {
      @apply bg-violet-600;
      outline: 7px solid white;
    }

    &:active {
      @apply bg-violet-800;
      transform: scale(0.9) translateY(10%);
      outline: 7px solid white;
    }
  }

  .button.button-primary-white {
    @apply bg-white text-violet-900 duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-violet-200;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px white;
      outline-offset: 2px;
    }
  }

  .button.button-primary-blue-light {
    @apply bg-white text-blue-900 duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-blue-200;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px white;
      outline-offset: 2px;
    }
  }

  .button.button-primary-blue-900 {
    @apply bg-blue-900 text-white duration-400 ease-appear transition-colors;

    &:hover,
    &:active {
      @apply bg-blue-800;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-900');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-blue-900 {
    @apply text-blue-900 duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.violet-900');

    &:hover {
      @apply bg-blue-900 bg-opacity-10;
    }

    &.active,
    &:active {
      @apply bg-blue-800 text-blue-100 bg-opacity-100;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.blue-900');
      outline-offset: 2px;
    }
  }

  .button.button-primary-link-header {
    @apply text-gray-700 duration-400 ease-appear transition-colors;

    &.active,
    &:hover {
      @apply bg-gray-100;
    }

    &:active {
      @apply bg-gray-200;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.gray-200');
      outline-offset: 2px;
    }
  }

  .button.button-primary-link-header-invert {
    @apply text-white duration-400 ease-appear transition-colors;

    &.active,
    &:hover {
      @apply bg-violet-800;
    }

    &:active {
      @apply bg-violet-700;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-700');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-gray {
    @apply text-black duration-400 ease-appear;
    box-shadow: inset 0 0 0 1px theme('colors.gray-300');

    &:hover,
    &:focus,
    &.active {
      box-shadow: inset 0 0 0 1px theme('colors.gray-400');
    }
  }

  .button.button-secondary-blue {
    @apply text-blue-900 duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.blue-800');

    &:hover {
      @apply bg-blue-900 bg-opacity-10;
    }

    &.active,
    &:active {
      @apply bg-blue-900 text-blue-100 bg-opacity-100;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.blue-900');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-violet {
    @apply text-violet-800 duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.violet-800');

    &:hover {
      @apply bg-violet-800 bg-opacity-10;
    }

    &.active,
    &:active {
      @apply bg-violet-800 text-violet-100 bg-opacity-100;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-800');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-violet-900 {
    @apply text-violet-800 duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.violet-900');

    &:hover {
      @apply bg-violet-900 bg-opacity-10;
    }

    &.active,
    &:active {
      @apply bg-violet-900 text-violet-100 bg-opacity-100;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-900');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-violet-300 {
    @apply text-violet-300 duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.violet-300');

    &:hover {
      @apply bg-violet-100 bg-opacity-20;
      box-shadow: inset 0 0 0 1px theme('colors.violet-100');
    }

    &.active,
    &:active {
      @apply bg-violet-100 bg-opacity-100 text-violet-900;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-100');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-violet-400 {
    @apply text-white duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.violet-400');

    &:hover {
      @apply bg-violet-200 bg-opacity-20;
      box-shadow: inset 0 0 0 1px theme('colors.violet-200');
    }

    &.active,
    &:active {
      @apply bg-violet-200 bg-opacity-100 text-violet-900;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.violet-200');
      outline-offset: 2px;
    }
  }

  .button.button-secondary-citron-300 {
    @apply text-white duration-400 ease-appear transition-colors;
    box-shadow: inset 0 0 0 1px theme('colors.citron-500');

    &:hover {
      @apply bg-citron-100 bg-opacity-20;
      box-shadow: inset 0 0 0 1px theme('colors.citron-100');
    }

    &.active,
    &:active {
      @apply bg-citron-100 bg-opacity-100 text-citron-900;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.citron-100');
      outline-offset: 2px;
    }
  }

  .button.button-mobile-menu-trigger {
    @apply bg-gray-100 duration-400 ease-appear;

    &:hover,
    &:focus,
    &.active {
      @apply bg-gray-200;
    }
  }

  .button.button-drawer-violet {
    @apply text-gray-600 duration-400 ease-appear transition-colors;

    &:hover {
      @apply bg-gray-100 text-gray-700;
    }

    &:active {
      @apply bg-gray-200;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.gray-200');
      outline-offset: 2px;
    }

    &.active {
      @apply text-violet-700;

      &:hover {
        @apply bg-violet-100;
      }

      &:active {
        @apply bg-violet-200;
      }

      &:focus-visible,
      &.focus-visible {
        outline: solid 1px theme('colors.violet-200');
        outline-offset: 2px;
      }
    }
  }

  .button.button-drawer-green {
    @apply text-gray-300 duration-400 ease-appear transition-colors;

    &:hover {
      @apply bg-gray-100 text-gray-700;
    }

    &:active {
      @apply bg-gray-200;
    }

    &:focus-visible,
    &.focus-visible {
      outline: solid 1px theme('colors.gray-200');
      outline-offset: 2px;
    }

    &.active {
      @apply text-green-700;

      &:hover {
        @apply bg-green-100;
      }

      &:active {
        @apply bg-green-200;
      }

      &:focus-visible,
      &.focus-visible {
        outline: solid 1px theme('colors.green-200');
        outline-offset: 2px;
      }
    }
  }

  .button.button-sparkle-violet {
    position: relative;
    background: theme('colors.violet-900');
    transition: 400ms theme('transitionTimingFunction.appear');
    box-shadow: 0 0 20px rgba(theme('colors.violet-600'), 0.25);

    .button-sparkle-gradient,
    .button-sparkle-spark,
    .button-sparkle-bg,
    .button-sparkle-glow,
    .button-sparkle-text {
      display: block;
    }

    .button-sparkle-spark {
      display: block;
      position: absolute;
      inset: 2px;
      border-radius: 100px;
      background: theme('colors.violet-800');
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 150%;
        aspect-ratio: 1/1;
        background: conic-gradient(transparent 50%, white 95%, transparent 100%);
        animation: button-sparkle-ai-rotate 4s infinite linear;
      }
    }

    .button-sparkle-bg {
      display: block;
      position: absolute;
      inset: 3px;
      border-radius: 100px;
      background: theme('colors.violet-800');
    }

    .button-sparkle-glow {
      display: block;
      position: absolute;
      inset: 3px;
      border-radius: 100px;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: radial-gradient(40% 90% at center 100%, theme('colors.violet-600'), transparent);
        transform: translateY(10px);
        transition: 400ms theme('transitionTimingFunction.appear');
      }
    }

    .button-sparkle-text {
      position: relative;
      z-index: 1;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    }

    &:hover,
    &:focus,
    &.active {
      transform: scale(1.05);
      box-shadow: 0 0 30px rgba(theme('colors.violet-600'), 0.5);

      .button-sparkle-glow:after {
        transform: translateY(0);
      }
    }
  }
}

@keyframes button-sparkle-ai-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

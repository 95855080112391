@layer components {
  .link-opacity {
    opacity: 0.5;
    transition: opacity 200ms theme('transitionTimingFunction.in-out-quad');

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  .link-opacity-hover {
    opacity: 1;
    transition: opacity 200ms theme('transitionTimingFunction.in-out-quad');

    &.active,
    &:hover {
      opacity: 0.5;
    }
  }

  .link-underline {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    text-decoration-color: currentColor;
    transition: text-decoration-color 300ms theme('transitionTimingFunction.in-out-quad');

    &.active,
    &:hover {
      text-decoration-color: transparent;
    }
  }

  .link-underline-hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    text-decoration-color: transparent;
    transition: text-decoration-color 300ms theme('transitionTimingFunction.in-out-quad');

    &.active,
    &:hover {
      text-decoration-color: currentColor;
    }
  }

  .link-arrow {
    position: relative;

    .link-arrow__icon {
      transition: opacity 200ms theme('transitionTimingFunction.in-out-quad'),
        transform 400ms theme('transitionTimingFunction.appear');
      opacity: 0;
      transform: translateX(5px);
    }

    &.active,
    &:focus,
    &:hover {
      .link-arrow__icon {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .link-arrow-external {
    position: relative;

    .link-arrow__icon {
      transition: transform 400ms theme('transitionTimingFunction.appear');
    }

    &.active,
    &:focus,
    &:hover {
      .link-arrow__icon {
        transform: translate(5px, -5px);
      }
    }
  }

  .link-cover {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

/*
// === Suisse Intl ===
*/

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-light-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-light-webm.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-lightitalic-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-lightitalic-webm.woff') format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-regular-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-regular-webm.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-regularitalic-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-regularitalic-webm.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-medium-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-medium-webm.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-mediumitalic-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-mediumitalic-webm.woff') format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-semibold-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-semibold-webm.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-semibolditalic-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-semibolditalic-webm.woff') format('woff');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-bold-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-bold-webm.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisse-intl/suisseintl-bolditalic-webm.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintl-bolditalic-webm.woff') format('woff');
  font-style: italic;
  font-weight: 800;
}

/*
// === Suisse Intl Mono ===
*/

@font-face {
  font-family: 'Suisse Intl Mono';
  src: url('/fonts/suisse-intl/suisseintlmono-thin-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintlmono-thin-webs.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Suisse Intl Mono';
  src: url('/fonts/suisse-intl/suisseintlmono-regular-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintlmono-regular-webs.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Suisse Intl Mono';
  src: url('/fonts/suisse-intl/suisseintlmono-bold-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseintlmono-bold-webs.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}

/*
// === Suisse Works ===
*/

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-regular-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-regular-webs.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-regularitalic-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-regularitalic-webs.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-medium-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-medium-webs.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-mediumitalic-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-mediumitalic-webs.woff') format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-bold-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-bold-webs.woff') format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('/fonts/suisse-intl/suisseworks-bolditalic-webs.woff2') format('woff2'),
    url('/fonts/suisse-intl/suisseworks-bolditalic-webs.woff') format('woff');
  font-style: italic;
  font-weight: 800;
}

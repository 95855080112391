@layer components {
  /*
	// === Main Rich Text ===
	*/
  .rich-text {
    a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
      text-decoration-color: currentColor;
      transition: text-decoration-color 300ms theme('transitionTimingFunction.in-out-quad');

      &.active,
      &:hover {
        text-decoration-color: transparent;
      }
    }

    b,
    strong {
      font-weight: 700;
    }

    em,
    i {
      font-style: italic;
    }

    ul li {
      position: relative;
      padding-left: 15px;

      &:before {
        content: '·';
        display: inline;
        position: absolute;
        left: 0;
      }
    }

    ul li ul li:before {
      content: '◦';
    }

    ul li ul li ul li:before {
      content: '‣';
    }

    ol {
      counter-reset: section;

      li {
        position: relative;
        padding-left: 30px;

        &:before {
          counter-increment: section;
          content: counter(section) '.';
          display: inline;
          position: absolute;
          left: 0;
        }
      }
    }

    ol li ol li:before {
      content: counter(section, lower-alpha) '.';
    }

    ol li ol li ol li:before {
      content: counter(section, lower-roman) '.';
    }
  }
}
